export const pageSizeOptions = [10, 20, 30, 50, 100]

export const initialStateClinic = {
    name: "",
    unit_suite: "",
    street_address: "",
    country: "",
    province_state: "",
    city: "",
    postal_code: "",
    latitude: "",
    longitude: "",
    is_delivery_address_different: false,
    delivery_unit_suite: "",
    delivery_street_address: "",
    delivery_country: "",
    delivery_province_state: "",
    delivery_city: "",
    delivery_postal_code: "",
    delivery_latitude: "",
    delivery_longitude: "",
    is_active: true,
    speciality: null,
    phone_1: "",
    phone_2: "",
    phone_3: "",
    phone_4: "",
    whatsapp: "",

    fax: "",
    email_1: "",
    email_2: "",
    email_3: "",
    website: "",
    facebook: "",
    twitter: "",
    yelp: "",
    linkedin: "",
    instagram: "",
    youtube: "",

    operatories: 0,
    chairs: 0,
    xrays: 0,
    computers: 0,
 
    wire_network: true,
    digital_xray: true,
    pan_xray: true,

    established_year: null,
    renovation: null,

    equipment_service_provider: null,
    handpiece_service_provider: null,
    it_service_provider: null,
    current_supplier: null,
    management_software: null,
    xray_software: null,
    sales_persons: null,
    equipment_service_technician: null,
    it_service_technician: null,

    description: "",

    clinic_service: null,
    division_access: null,
    clinic_image: null,

    timezone:"",
    monday_open: false,
    monday_to: "",
    monday_from: "",  

    tuesday_open: false,
    tuesday_to: "",
    tuesday_from: "", 

    wednesday_open: false,
    wednesday_to: "",
    wednesday_from: "", 

    thursday_open: false,
    thursday_to: "",
    thursday_from: "",  

    friday_open: false,
    friday_to: "",
    friday_from: "",    

    saturday_open: false,
    saturday_to: "",
    saturday_from: "",

    sunday_open: false,
    sunday_to: "",
    sunday_from: "",
    branches: [],


}

export const initialStateLab = {
    name: "",
    unit_suite: "",
    street_address: "",
    country: "",
    province_state: "",
    city: "",
    postal_code: "",
    latitude: "",
    longitude: "",
    is_delivery_address_different: false,
    delivery_unit_suite: "",
    delivery_street_address: "",
    delivery_country: "",
    delivery_province_state: "",
    delivery_city: "",
    delivery_postal_code: "",
    delivery_latitude: "",
    delivery_longitude: "",
    is_active: true,
    phone_1: "",
    phone_2: "",
    phone_3: "",
    phone_4: "",
    whatsapp: "",

    fax: "",
    email_1: "",
    email_2: "",
    email_3: "",
    website: "",
    facebook: "",
    twitter: "",
    yelp: "",
    linkedin: "",
    instagram: "",
    youtube: "",

    operatories: 0,
    chairs: 0,
    xrays: 0,
    computers: 0,

    wire_network: true,
    digital_xray: true,
    pan_xray: true,

    established_year: null,
    renovation: null,

    equipment_service_provider: null,
    handpiece_service_provider: null,
    it_service_provider: null,
    current_supplier: null,
    management_software: null,
    xray_software: null,
    sales_persons: null,
    equipment_service_technician: null,
    it_service_technician: null,

    description: "",
    note: "",

    lab_service: null,
    division_access: null,
    lab_image: null,

    timezone:"",
    monday_open: false,
    monday_to: "",
    monday_from: "",  

    tuesday_open: false,
    tuesday_to: "",
    tuesday_from: "", 

    wednesday_open: false,
    wednesday_to: "",
    wednesday_from: "", 

    thursday_open: false,
    thursday_to: "",
    thursday_from: "",  

    friday_open: false,
    friday_to: "",
    friday_from: "",    

    saturday_open: false,
    saturday_to: "",
    saturday_from: "",

    sunday_open: false,
    sunday_to: "",
    sunday_from: "",

    branches: [],

}

export const initialStateVC = {
  name: "",
  unit_suite: "",
  street_address: "",
  country: "",
  province_state: "",
  city: "",
  postal_code: "",
  latitude: "",
  longitude: "",
  is_delivery_address_different: false,
  delivery_unit_suite: "",
  delivery_street_address: "",
  delivery_country: "",
  delivery_province_state: "",
  delivery_city: "",
  delivery_postal_code: "",
  delivery_latitude: "",
  delivery_longitude: "",
  is_active: true,
  is_verified: false,
  speciality: null,
  phone_1: "",
  phone_2: "",
  phone_3: "",
  phone_4: "",
  whatsapp: "",

  fax: "",
  email_1: "",
  email_2: "",
  email_3: "",
  website: "",
  facebook: "",
  twitter: "",
  yelp: "",
  linkedin: "",
  instagram: "",
  youtube: "",

  operatories: 0,
  xrays: 0,
  computers: 0,

  wire_network: true,
  digital_xray: true,
  established_year: null,
  renovation: null,

  equipment_service_provider: null,
  handpiece_service_provider: null,
  it_service_provider: null,
  current_supplier: null,
  management_software: null,
  xray_software: null,
  sales_persons: null,
  equipment_service_technician: null,
  it_service_technician: null,

  description: "",

  veterinary_clinic_service: null,
  division_access: null,
  veterinary_clinic_image: null,

  timezone:"",
  monday_open: false,
  monday_to: "",
  monday_from: "",  

  tuesday_open: false,
  tuesday_to: "",
  tuesday_from: "", 

  wednesday_open: false,
  wednesday_to: "",
  wednesday_from: "", 

  thursday_open: false,
  thursday_to: "",
  thursday_from: "",  

  friday_open: false,
  friday_to: "",
  friday_from: "",    

  saturday_open: false,
  saturday_to: "",
  saturday_from: "",

  sunday_open: false,
  sunday_to: "",
  sunday_from: "",

  branches: [],

}

export  const initialStateAssistant = {
    first_name: "",
    last_name: "",
    unit_suite: "",
    street_address: "",
    country: "",
    province_state: "",
    city: "",
    postal_zip_code: "",
    latitude: "",
    longitude: "",
    date_of_birth: "",
    gender: "",

    is_active: true,

    phone_mobile: "",
    phone_other: "",
    fax: "",
    whatsapp: "",
    email: "",
    email_2: "",
    website: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    language: null,

    university_college: "",
    year_graduated: "",
    year_of_experience: "",
    type_of_work: null,
    speciality: "",
    how_hear_us: "",

    description: "",
    profile_image: null,
    resume: null,
    division_access: null,
}

export const initialStateDTech = {
    first_name: "",
    last_name: "",
    unit_suite: "",
    street_address: "",
    country: "",
    province_state: "",
    city: "",
    postal_zip_code: "",
    latitude: "",
    longitude: "",
    date_of_birth: "",
    gender: "",

    is_active: true,
    is_verified: false,
    phone_mobile: "",
    phone_other: "",
    fax: "",
    whatsapp: "",
    email: "",
    email_2: "",
    website: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    language: null,

    university_college: "",
    year_graduated: "",
    year_of_experience: "",
    type_of_work: null,
    speciality: "",
    how_hear_us: "",

    service_range : '',
    capable_storing_spare_parts : true,
    drive : true,
    insurance : true,
    internet_connection : true,
    service_abiity:null,

    description: "",
    profile_image: null,
    resume: null,    
    certificate: null,
    id_image: null,
    division_access: null,

    timezone:"",
    monday_open: false,
    monday_to: "",
    monday_from: "",  

    tuesday_open: false,
    tuesday_to: "",
    tuesday_from: "", 

    wednesday_open: false,
    wednesday_to: "",
    wednesday_from: "", 

    thursday_open: false,
    thursday_to: "",
    thursday_from: "",  

    friday_open: false,
    friday_to: "",
    friday_from: "",    

    saturday_open: false,
    saturday_to: "",
    saturday_from: "",

    sunday_open: false,
    sunday_to: "",
    sunday_from: "",


}

export   const initialStateDentist = {
    first_name: "",
    last_name: "",
    unit_suite: "",
    street_address: "",
    country: "",
    province_state: "",
    city: "",
    postal_zip_code: "",
    latitude: "",
    longitude: "",
    date_of_birth: "",
    gender: "",

    is_active: true,
    is_verified: false,
    phone_mobile: "",
    phone_other: "",
    fax: "",
    whatsapp: "",
    email: "",
    email_2: "",
    website: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    language: null,

    university_college: "",
    year_graduated: "",
    year_of_experience: "",
    type_of_work: null,
    speciality: "",
    how_hear_us: "",

    description: "",
    profile_image: null,
    resume: null,
    division_access: null,
}

export   const initialStateHygienist = {
    first_name: "",
    last_name: "",
    unit_suite: "",
    street_address: "",
    country: "",
    province_state: "",
    city: "",
    postal_zip_code: "",
    latitude: "",
    longitude: "",
    date_of_birth: "",
    gender: "",

    is_active: true,

    phone_mobile: "",
    phone_other: "",
    fax: "",
    whatsapp: "",
    email: "",
    email_2: "",
    website: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    language: null,
   
    university_college: "",
    year_graduated: "",
    year_of_experience: "",
    type_of_work: null,
    speciality: "",
    how_hear_us: "",

    description: "",
    profile_image: null,
    resume: null,
    division_access: null,
}

export   const initialStateLTech = {
    first_name: "",
    last_name: "",
    unit_suite: "",
    street_address: "",
    country: "",
    province_state: "",
    city: "",
    postal_zip_code: "",
    latitude: "",
    longitude: "",
    date_of_birth: "",
    gender: "",

    is_active: true,

    phone_mobile: "",
    phone_other: "",
    fax: "",
    whatsapp: "",
    email: "",
    email_2: "",
    website: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    language: null,

    university_college: "",
    year_graduated: "",
    year_of_experience: "",
    type_of_work: null,
    speciality: "",
    how_hear_us: "",

    description: "",
    profile_image: null,
    resume: null,
    division_access: null,
}

export const initialStateStaff = {
    first_name: "",
    last_name: "",
    unit_suite: "",
    street_address: "",
    country: "",
    province_state: "",
    city: "",
    postal_zip_code: "",
    latitude: "",
    longitude: "",
    date_of_birth: "",
    gender: "",

    is_active: true,
    is_verified: false,
    phone_mobile: "",
    phone_other: "",
    fax: "",
    whatsapp: "",
    email: "",
    email_2: "",
    website: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    language: null,

    university_college: "",
    year_graduated: "",
    year_of_experience: "",
    type_of_work: null,
    speciality: "",
    how_hear_us: "",

    description: "",
    profile_image: null,
    resume: null,
    division_access: null,
}


