import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


const Navbar = () => {
  const [isSuperUser, setIsSuperUser] = useState(false)

  const is_superuser = localStorage.getItem("is_superuser")

  useEffect(() => {
      document.title = "Dentals | Settings"
      if (is_superuser){
        setIsSuperUser(true)
      }
  }, [is_superuser]);

  return (

    <>
      <div className="w98p ml1p fl  box-shadow-2 mb5 bg-w">



        <div className="fw fl dn db-1175 bb-blue mb10">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">

                <div className="fw fl ">
                  <div className="w50p fl">
                    <Link to="/">
                        <span className="Bauhaus_Medium_BT fs60 lh60">1db.ca</span>
                    </Link>
                  </div> 

                  <div className="w50p fl tr mt20">

                  { isSuperUser && (
                        <Link to="/settings" className="mr10">
                          Settings
                        </Link>  
                      )
                      }
                    


                    <Link to="/login" className=""  >
                      Logout
                    </Link>
                  </div> 
                </div>   

              </div>
            </div>
          </div>
        </div>




        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">

                  <div className="fw fl mb10">

                    <div className="w15p fl  dn-1175">
                      <Link to="/">
                            <span className="Bauhaus_Medium_BT fs60 lh60">1db.ca</span>
                        </Link>
                    </div>   
                 
                    <div className="w75p fw-1175 fl mt30 mt0-1175">
                      <ul className="dental_links fw fl">
                        <li><Link  to="/">Dashboard</Link></li>
                        <li><Link to="/clinics">Clinics</Link></li>
                        <li><Link  to="/dentists"> Dentists</Link></li>
                        <li><Link to="/hygienists">Hygienists</Link></li>
                        <li> <Link to="/assistants"> Assistants</Link></li>
                        <li><Link  to="/staffs">Staff</Link></li>
                        <li><Link to="/dental_technicians">Dental Technicians</Link></li>
                        <li><Link to="/labs" >Labs</Link></li>
                        <li><Link to="/lab_technicians" >Lab Technicians</Link></li>
                        <li><Link to="/veterinary_clinics" >  Veterinary Clinics</Link></li>
                      </ul>
                    </div>
             
                    <div className="w10p fl tr dn-1175 mt30 mt0-1175">

                      { isSuperUser && (
                        <Link to="/settings" className="mr10">
                          Settings
                        </Link>  
                      )
                      }
                    
                      
                      <Link to="/logout"  >
                        Logout
                      </Link>

                    </div>
                  </div>
            </div>
          </div>
        </div>


      </div>




    {/* <nav className="bg-gray-100 border-gray-200">
      <div
        className={`mt-1 border-gray-200 shadow-sm bg-gray-50 md:bg-gray-100 border-y ${
          isDropdownOpen ? "block" : "hidden"
        }`}
      >
        <div className="grid max-w-screen-xl px-4 py-5 mx-auto text-black sm:grid-cols-2 md:px-6"></div>
      </div>
    </nav> */}


    </>

  );
};

export default Navbar;
