import React, { useState, useEffect } from 'react';
import { useNavigate, useParams} from 'react-router-dom'
import axios from 'axios';
import Navbar from "../../../global/components/common/NavBar";
import Footer from "../../../global/components/common/Footer";
import HtmlRender from '../../../global/utils/HtmlRender';
import { HandleDeliveryAddress } from '../../../global/utils/HandleDeliveryAddress';
import RadioButtonGroup from '../../../global/utils/RadioButtonGroup';
import { TimeOpenClose } from '../../../global/utils/TimeOpenClose';
import { DatePicker, Select, Checkbox, Upload, Button,  Switch } from 'antd';
import dayjs from 'dayjs'; 
import TimezoneSelect from '../../../global/utils/TimezoneSelect';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import MultiSelectAutoComplete from '../../../global/utils/MultiSelectAutoComplete'
import { validateFormTwo } from '../../../global/utils/Validators'
import PhoneNumber from '../../../global/utils/PhoneNumber'
import { PhoneNumberValidateOne } from '../../../global/utils/PhoneNumberValidate'
import AddressInput from "../../../global/utils/AddressInput"
import AddressInputDelivery from '../../../global/utils/AddressInputDelivery'
import { disableFutureDates } from '../../../global/utils/AtLeast18'
import { initialStateVC } from '../../../global/utils/PageConfig';

const initialState = initialStateVC 

const { Option } = Select;

const VeterinaryClinicEdit = () => {

  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const gmap_api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;


  const [dataAll, setDataAll] = useState(initialState);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorStatus, setErrorStatus] = useState(false);
  const [error, setError] = useState('');
  const [errors, setErrors] = useState([]);

  const [equipServiceProviders, setEquipServiceProviders] = useState([]);
  const [handpieceServiceProviders, setHandpieceServiceProviders] = useState([]);
  const [itServiceProviders, setITServiceProviders] = useState([]);
  const [currentSuppliers, setCurrentSuppliers] = useState([]);
  const [managementSoftwares, setManagementSoftwares] = useState([]);
  const [xraySoftwares, setXraySoftwares] = useState([]);
  const [clinicServices, setClinicServices] = useState([]);
  const [divisionAccess, setDivisionAccess] = useState([]);
  const [salesPersons, setSalesPersons] = useState([]);
  const [equipmentServiceTechnicians, setEquipmentServiceTechnicians] = useState([]);
  const [itServiceTechnicians, setITServiceTechnicians] = useState([]);

  const [loading, setLoading] = useState(false)
  const [fileList, setFileList] = useState([])

  const timeIntervals = TimeOpenClose();


  useEffect(() => {
    document.title = "Update VeterinaryClinic"
    if (!token){
      navigate(`/login`)
    }

    const apiSpecialityUrl = `${baseURL}/veterinaryClinics/api/createdata/`

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
    }
    axios({
        'method':'GET',
        'url':apiSpecialityUrl,
        'headers': headers,
    })
    .then((response) => {
      setEquipServiceProviders(response.data.equipment_service_providers)
      setHandpieceServiceProviders(response.data.handpiece_services_providers)
      setITServiceProviders(response.data.it_service_providers)
      setCurrentSuppliers(response.data.current_suppliers)
      setManagementSoftwares(response.data.management_softwares)
      setXraySoftwares(response.data.xray_softwares)
      setClinicServices(response.data.clinic_services)
      setDivisionAccess(response.data.division_access)
      setSalesPersons(response.data.sales_persons)
      setEquipmentServiceTechnicians(response.data.equipment_service_technicians)
      setITServiceTechnicians(response.data.it_service_technicians)
    })
    .catch((error) => {
        console.log(error)
    });


    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/veterinaryClinics/api/${id}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })

        setDataAll(response.data.data)

        if (response.data.data.veterinary_clinic_image) {
          setFileList([
            {
              uid: '-1', 
              name: 'image.jpeg', 
              status: 'done', 
              url: response.data.data.veterinary_clinic_image, 
            },
          ])
        }

      } catch (err) {
        console.error(err);
      } 
    };

    fetchData();


  }, [token,baseURL,navigate,id]);

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setDataAll((prevState) => ({
      ...prevState,
      [name]: value === 'true' 
    }));
  }




  const handleUploadChange = ({ fileList }) => {
    if (fileList.length > 1) {
      fileList = fileList.slice(-1)
    }
    setDataAll((prevState) => ({
      ...prevState,
      veterinary_clinic_image: fileList[0]?.originFileObj || null,
    }))    
    setFileList(fileList)
  }

  // Handle date changes
  const handleChangeDate = (date, dateString, name) => {

    const dayjsDate = dayjs(dateString, 'MM/DD/YYYY');
    const formattedDate = dayjsDate.isValid() ? dayjsDate.format('YYYY-MM-DD') : null;

    setDataAll((prevState) => ({
      ...prevState,
      [name]: formattedDate,
    }));
  };

  const handleChangeMultipleSelect = (selectedValues, name) => {
    setDataAll((prevState) => ({
      ...prevState,
      [name]: selectedValues,
    }));
  };


  const handleTimezoneChange = (name, value) => {
    setDataAll((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setDataAll((prevState) => ({
        ...prevState,
        [name]: files[0] || null,
      })); 
    }else if (type === "number") {
      setDataAll((prevState) => ({
        ...prevState,
        [name]: parseInt(value, 10) || 0, 
      }));
    }else {
      setDataAll((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleCheckboxChange = (name) => (checkedValues) => {

    setDataAll((prevState) => ({
      ...prevState,
      [name]: checkedValues,
    }));
  };



  const handleSwitchChange = (name, checked) => {
    setDataAll((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  const handleBranches = (name, values) => {
      setDataAll(prevState => ({
          ...prevState,
          [name]: values 
      }));
  };
 
//phone handle 
const handlePhoneChange = (field) => (value) => {
  setDataAll((prevState) => ({
    ...prevState,
    [field]: value,
  }));
};
//phone handle 


const handleSubmit = async (e) => {
  e.preventDefault()

  const validationErrors = validateFormTwo(dataAll)
  if (validationErrors.length > 0) {
    setError('')
    setErrors(validationErrors)
    return
  }

  const validationPhoneErrors = PhoneNumberValidateOne(dataAll)
  if (validationPhoneErrors.length > 0) {
      setError('')
      setErrors(validationPhoneErrors)
      return
  }



  
  setErrors([]);

  setLoading(true)
 
  try {
    let response;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`,
      },
    };

    response = await axios.put(
      `${baseURL}/veterinaryClinics/api/${id}/update/`, 
      dataAll,
      config
      );

    var status = response.data.status
    var message = response.data.message
      
    if (status === 1){
      setSuccessMessage(message)
      setSuccess(true)
      setErrorStatus(false)
      setDataAll(initialState)
      toast.success(message)
      setTimeout(() => {
        navigate('/veterinary_clinics')
      }, 2000)
    }else{
      setSuccess(false)
      setErrorStatus(true)
      setError(message)
      setErrors([])
    }


  } catch (error) {
    console.error("Error submitting Veterinary Clinic:", error);
  } finally {
    setLoading(false)
  }
}





  return (
    <>
      <Navbar />
      <div className="w98p ml1p fl mt20 h75vh_m">

        <div className='fw fl box-shadow-2 mb5 bg-w p10'>

          {/* <h2 className="fw fl tc mb20">Update Veterinary Clinic</h2> */}

          <form onSubmit={handleSubmit} className="">

            <div className='fw fl'>

              {/* new design start */}

              <div className='container-fluid'>
                <div className='row'>

                  <div className='col-md-4'>

                    {/* segment start veterinaryClinic information*/}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>

                        <p>VETERINARY CLINIC </p>

                      </div>

                      <div className='fw fl b-blue rad4 p10 input_text'>

                        <div className="row">
                          <div className="col-md-12">
                              <div className="fw fl rel">
                                  <label>Name</label>
                                  <input className="required" name="name" value={dataAll.name || ''} type="text" onChange={handleChange} />
                              </div>
                          </div>
                        </div>

                      
                        <div className="row">
                            <div className="col-md-12">
                                <div className="fw fl rel">
                                    <label>Street Address</label>
                                    <AddressInput
                                        gmapApiKey={gmap_api_key}
                                        dataAll={dataAll}
                                        setDataAll={setDataAll}
                                      />
                                </div>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-md-6">
                                <div className="fw fl rel">
                                    <label>Unit/Suite</label>
                                    <input className=""  name="unit_suite" value={dataAll.unit_suite || ''} type="text" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="fw fl rel">
                                    <label>City</label>
                                    <input className=""  name="city" type="text" value={dataAll.city || ''} onChange={handleChange} />
                                </div>
                            </div>



                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="fw fl rel">
                                    <label>Province/State</label>
                                    <input className=""  name="province_state" type="text" value={dataAll.province_state || ''} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="fw fl rel">
                                    <label>Country</label>
                                    <input className=""  name="country" type="text" value={dataAll.country || ''} onChange={handleChange} />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="fw fl rel">
                                    <label>Postal/Zip Code</label>
                                    <input className=""  name="postal_code" type="text" value={dataAll.postal_code || ''} onChange={handleChange} />
                                </div>
                            </div>
                        </div>

                        <div className="fw fl mt10 ">

                            <label className="form-check-inline1">Different address for delivery
                                <input className="rel top3 left5" name="is_delivery_address_different" type="checkbox" checked={dataAll.is_delivery_address_different} onChange={(e) => HandleDeliveryAddress(e, setDataAll)} />
                                <span className="checkmark floatlabelup"></span>
                            </label>

                        </div>


                        <div className={`rel fw fl billing_address_a mt20 ${!dataAll.is_delivery_address_different ? 'dn' : ''}`} >


                          <div className="row">
 
                              <div className="col-md-12">
                                  <div className="fw fl rel">
                                      <label>Street Address</label>

                                      <AddressInputDelivery
                                        gmapApiKey={gmap_api_key}
                                        dataAll={dataAll}
                                        setDataAll={setDataAll}
                                      />

                                  </div>
                              </div>
                          </div>

                          <div className="row">

                              <div className="col-md-6">
                                  <div className="fw fl rel">
                                      <label>Unit/Suite</label>
                                      <input  type="text" className=""  name="delivery_unit_suite" value={dataAll.delivery_unit_suite || ''} onChange={handleChange} />
                                  </div>
                              </div>

                              <div className="col-md-6">
                                  <div className="fw fl rel">
                                      <label>City</label>
                                      <input  type="text" className=""  name="delivery_city" value={dataAll.delivery_city || ''}  onChange={handleChange} />
                                  </div>
                              </div>
                          </div>
                          
                          <div className="row">

                              <div className="col-md-4">
                                  <div className="fw fl rel">
                                      <label>Province/State</label>
                                      <input  type="text" className=""  name="delivery_province_state" value={dataAll.delivery_province_state || ''}   onChange={handleChange} />
                                  </div>
                              </div>

                              <div className="col-md-4">
                                  <div className="fw fl rel">
                                      <label>Country</label>
                                      <input  type="text" className=""  name="delivery_country" value={dataAll.delivery_country || ''}  onChange={handleChange} />
                                  </div>
                              </div>


                              <div className="col-md-4">
                                  <div className="fw fl rel">
                                      <label>Postal/Zip Code</label>
                                      <input type="text" className=""  name="delivery_postal_code" value={dataAll.delivery_postal_code || ''}   onChange={handleChange} />
                                  </div>
                              </div>
                          </div>



                        </div>

                      </div>
                    </div>
                    {/* segment end*/}                   

                    {/* segment start - veterinaryClinic status*/}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>

                        <p>VETERINARY CLINIC STATUS </p>

                      </div>

                      <div className='fw fl b-blue rad4 p10'>

                          <div className="fw fl ">
                            <span className="fw fl">
                              <RadioButtonGroup
                                label="Is Active"
                                name="is_active"
                                selectedValue={dataAll.is_active}
                                onChange={handleRadioChange}
                                leftw="w50p"
                                rightw="w50p"
                              />
                            </span>
                          </div>

                        <p className='fw fl fs12 lh15 mt15'>If status is Inactive then the Veterinary Clinic is no longer to get login access into the divisions</p>

                      </div>

                    </div>
                    {/* segment end*/}

                    {/* segment start -  verification*/}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>
                        <p>VETERINARY CLINIC VERIFICATION </p>
                      </div>

                      <div className='fw fl b-blue rad4 p10'>

                          <div className="fw fl ">
                            <span className="fw fl">
                              <RadioButtonGroup
                                label="Is Verified"
                                name="is_verified"
                                selectedValue={dataAll.is_verified}
                                onChange={handleRadioChange}
                                leftw="w50p"
                                rightw="w50p"
                              />
                            </span>
                          </div>

                        <p className='fw fl fs12 lh15 mt15'>
                          If the status is not verified, the veterinary clinic needs to have it verified by an admin.
                        </p>

                      </div>

                    </div>
                    {/* segment end*/}

                    {/* segment start - working hours*/}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>

                        <p>WORKING HOURS</p>

                      </div>

                      <div className='fw fl b-blue rad4 p10'>

                        <div className="fw fl mb10">
                          <label>Select Time Zone</label>
                          <TimezoneSelect name="timezone"  onTimezoneChange={handleTimezoneChange} value={dataAll.timezone}/>
                        </div>

                        {dataAll.timezone &&      

                        <div className="fw fl">

                          <div className="switch_main">
                            <div className="switch_main_left">
                              <span className='switch_span'>Monday:</span>

                              <Switch size="small"  className='fl' value={dataAll.monday_open  || ''} 
                                onChange={(checked) => handleSwitchChange('monday_open', checked)}
                              />

                            </div>                          

                            {dataAll.monday_open && 

                              <div className="switch_main_right">
                                <div className="w45p fl pl5 ">

                                  <select className="w90p fl custom_select_mini" name="monday_from" value={dataAll.monday_from  || ''}   onChange={handleChange} >
                                    {timeIntervals.map((time, index) => (
                                      <option key={index} value={time}>
                                        {time}
                                      </option>
                                    ))}
                                  </select>

                                </div>                              

                                <div className="w10p fl tc ">
                                  <p className='rel top5'>To</p>
                                </div>

                                <div className="w45p fl">
                                    <select className="w90p fr custom_select_mini" name="monday_to" value={dataAll.monday_to  || ''}  onChange={handleChange} >
                                      {timeIntervals.map((time, index) => (
                                        <option key={index} value={time}>
                                          {time}
                                        </option>
                                      ))}
                                    </select>
                                </div>
                              </div>
                            }
                          </div>


                          <div className="switch_main">
                            <div className="switch_main_left">
                              <span className='switch_span'>Tuesday:</span>

                              <Switch size="small"  className='fl'  value={dataAll.tuesday_open  || ''} 
                                onChange={(checked) => handleSwitchChange('tuesday_open', checked)}
                              />
                            </div>                          
                            
                            {dataAll.tuesday_open && 

                              <div className="switch_main_right">
                                <div className="w45p fl pl5 ">
                                  <select className="w90p fl custom_select_mini" name="tuesday_from" value={dataAll.tuesday_from  || ''}   onChange={handleChange} >
                                    {timeIntervals.map((time, index) => (
                                      <option key={index} value={time}>
                                        {time}
                                      </option>
                                    ))}
                                  </select>
                                </div>                              

                                <div className="w10p fl tc ">
                                  <p className='rel top5'>To</p>
                                </div>

                                <div className="w45p fl">
                                    <select className="w90p fr custom_select_mini" name="tuesday_to" value={dataAll.tuesday_to  || ''}  onChange={handleChange} >
                                      {timeIntervals.map((time, index) => (
                                        <option key={index} value={time}>
                                          {time}
                                        </option>
                                      ))}
                                    </select>
                                </div>
                              </div>
                            }
                          </div>


                          <div className="switch_main">
                            <div className="switch_main_left">
                              <span className='switch_span'>Wednesday:</span>

                              <Switch size="small"  className='fl' value={dataAll.wednesday_open  || ''} 
                                onChange={(checked) => handleSwitchChange('wednesday_open', checked)}
                              />
                            </div>                          
                            
                            {dataAll.wednesday_open && 

                              <div className="switch_main_right">
                                <div className="w45p fl pl5 ">
                                  <select className="w90p fl custom_select_mini" name="wednesday_from" value={dataAll.wednesday_from  || ''}   onChange={handleChange}  >
                                    {timeIntervals.map((time, index) => (
                                      <option key={index} value={time}>
                                        {time}
                                      </option>
                                    ))}
                                  </select>
                                </div>                              

                                <div className="w10p fl tc ">
                                  <p className='rel top5'>To</p>
                                </div>

                                <div className="w45p fl">
                                    <select className="w90p fr custom_select_mini" name="wednesday_to" value={dataAll.wednesday_to  || ''}  onChange={handleChange} >
                                      {timeIntervals.map((time, index) => (
                                        <option key={index} value={time}>
                                          {time}
                                        </option>
                                      ))}
                                    </select>
                                </div>
                              </div>
                            }
                          </div>


                          <div className="switch_main">
                            <div className="switch_main_left">
                              <span className='switch_span'>Thursday:</span>

                              <Switch size="small"  className='fl' value={dataAll.thursday_open  || ''}
                                onChange={(checked) => handleSwitchChange('thursday_open', checked)}
                              />
                            </div>                          
                            
                            {dataAll.thursday_open && 

                              <div className="switch_main_right">
                                <div className="w45p fl pl5 ">
                                  <select className="w90p fl custom_select_mini" name="thursday_from"  value={dataAll.thursday_from  || ''} onChange={handleChange} >
                                    {timeIntervals.map((time, index) => (
                                      <option key={index} value={time}>
                                        {time}
                                      </option>
                                    ))}
                                  </select>
                                </div>                              

                                <div className="w10p fl tc ">
                                  <p className='rel top5'>To</p>
                                </div>

                                <div className="w45p fl">
                                    <select className="w90p fr custom_select_mini" name="thursday_to" value={dataAll.thursday_to  || ''}  onChange={handleChange} >
                                      {timeIntervals.map((time, index) => (
                                        <option key={index} value={time}>
                                          {time}
                                        </option>
                                      ))}
                                    </select>
                                </div>
                              </div>
                            }
                          </div>


                          <div className="switch_main">
                            <div className="switch_main_left">
                              <span className='switch_span'>Friday:</span>

                              <Switch size="small"  className='fl' value={dataAll.friday_open  || ''} 
                                onChange={(checked) => handleSwitchChange('friday_open', checked)}
                              />
                            </div>                          
                            
                            {dataAll.friday_open && 

                              <div className="switch_main_right">
                                <div className="w45p fl pl5 ">
                                  <select className="w90p fl custom_select_mini" name="friday_from" value={dataAll.friday_from  || ''}   onChange={handleChange} >
                                    {timeIntervals.map((time, index) => (
                                      <option key={index} value={time}>
                                        {time}
                                      </option>
                                    ))}
                                  </select>
                                </div>                              

                                <div className="w10p fl tc ">
                                  <p className='rel top5'>To</p>
                                </div>

                                <div className="w45p fl">
                                    <select className="w90p fr custom_select_mini" name="friday_to" value={dataAll.friday_to  || ''}  onChange={handleChange} >
                                      {timeIntervals.map((time, index) => (
                                        <option key={index} value={time}>
                                          {time}
                                        </option>
                                      ))}
                                    </select>
                                </div>
                              </div>
                            }
                          </div>


                          <div className="switch_main">
                            <div className="switch_main_left">
                              <span className='switch_span'>Saturday:</span>

                              <Switch size="small"  className='fl' value={dataAll.saturday_open  || ''}
                                onChange={(checked) => handleSwitchChange('saturday_open', checked)}
                              />
                            </div>                          
                            
                            {dataAll.saturday_open && 

                              <div className="switch_main_right">
                                <div className="w45p fl pl5 ">
                                  <select className="w90p fl custom_select_mini" name="saturday_from" value={dataAll.saturday_from  || ''} onChange={handleChange} >
                                    {timeIntervals.map((time, index) => (
                                      <option key={index} value={time}>
                                        {time}
                                      </option>
                                    ))}
                                  </select>
                                </div>                              

                                <div className="w10p fl tc ">
                                  <p className='rel top5'>To</p>
                                </div>

                                <div className="w45p fl">
                                    <select className="w90p fr custom_select_mini" name="saturday_to" value={dataAll.saturday_to  || ''} onChange={handleChange} >
                                      {timeIntervals.map((time, index) => (
                                        <option key={index} value={time}>
                                          {time}
                                        </option>
                                      ))}
                                    </select>
                                </div>
                              </div>
                            }
                          </div>


                          <div className="switch_main">
                            <div className="switch_main_left">
                              <span className='switch_span'>Sunday:</span>

                              <Switch size="small"  className='fl' value={dataAll.sunday_open  || ''}
                                onChange={(checked) => handleSwitchChange('sunday_open', checked)}
                              />
                            </div>                          
                            
                            {dataAll.sunday_open && 

                              <div className="switch_main_right">
                                <div className="w45p fl pl5 ">
                                  <select className="w90p fl custom_select_mini" name="sunday_from" value={dataAll.sunday_from  || ''} onChange={handleChange} >
                                    {timeIntervals.map((time, index) => (
                                      <option key={index} value={time}>
                                        {time}
                                      </option>
                                    ))}
                                  </select>
                                </div>                              

                                <div className="w10p fl tc ">
                                  <p className='rel top5'>To</p>
                                </div>

                                <div className="w45p fl">
                                    <select className="w90p fr custom_select_mini" name="sunday_to" value={dataAll.sunday_to  || ''}  onChange={handleChange} >
                                      {timeIntervals.map((time, index) => (
                                        <option key={index} value={time}>
                                          {time}
                                        </option>
                                      ))}
                                    </select>
                                </div>
                              </div>
                            }
                          </div>


                        </div>
                        }  


                      </div>

                    </div>
                    {/* segment end*/}   

                    {/* segment start - veterinaryClinic  details*/}
                    <div className='fw fl mt20'>

                      <div className='fw fl segment_heading'>

                        <p>VETERINARY CLINIC DETAILS </p>

                      </div>

                      <div className='fw fl b-blue rad4 p10 input_text'>

                        <div className="row">

                          <div className="col-md-6 col-lg-5 ">
                              <div className="fw  fl mb10">
                                  <span className="">Operatories</span> 
                                  <input type="number" name="operatories" value={dataAll.operatories  || ''}  className="fr w50 pl5 rad4 b-grey" step="1" min="0" onChange={handleChange} />
                              </div>

                              <div className="fw fl mb10 ">
                                  <span className="">X-Ray's</span> 
                                  <input type="number" name="xrays" value={dataAll.xrays  || ''} className="fr w50 pl5 rad4 b-grey" step="1" min="0" onChange={handleChange} />
                              </div>
                              <div className="fw fl mb10">
                                  <span className="">Computers</span> 
                                  <input type="number" name="computers" value={dataAll.computers  || ''}  className="fr w50 pl5 rad4 b-grey" step="1" min="0" onChange={handleChange} />
                              </div>
                          </div>

                          <div className="col-md-6 col-lg-6 col-lg-offset-1 ">

                              <div className="fw fl ">
                                  <span className="fr">
                                  <RadioButtonGroup
                                    label="Wire Network"
                                    name="wire_network"
                                    selectedValue={dataAll.wire_network}
                                    onChange={handleRadioChange}
                                    checked={dataAll.wire_network  || ''} 
                                  />
                                  </span>
                              </div>

                              <div className="fw fl ">
                                  <span className="fr">
                                  <RadioButtonGroup
                                      label="Digital X-Ray"
                                      name="digital_xray"
                                      selectedValue={dataAll.digital_xray}
                                      onChange={handleRadioChange}
                                      checked={dataAll.digital_xray  || ''} 
                                  />
                                  </span>
                              </div>



                          </div>


                          <div className="row mb10 datepicker_one">
                              
                              <div className="col-md-6 ">
                                  <span className="fl mt8">Established Year</span>
                                <DatePicker 
                                  onChange={(date, dateString) => handleChangeDate(date, dateString, 'established_year')} 
                                  format="MM/DD/YYYY" 
                                  value={dataAll.established_year ? dayjs(dataAll.established_year) : null}
                                  disabledDate={disableFutureDates}
                                />
                              </div>
                        
                              <div className="col-md-6 ">
                                  <span className="fl mt8">Renovation</span>
                                  {/* <input name="renovation" type="text"  className="dp2 fs12 pl15 form-control b-grey2" data-toggle="datepicker" autoComplete="off" /> */}

                                <DatePicker 
                                  onChange={(date, dateString) => handleChangeDate(date, dateString, 'renovation')} 
                                  format="MM/DD/YYYY" 
                                  value={dataAll.renovation ? dayjs(dataAll.renovation) : null}
                                  disabledDate={disableFutureDates}
                                />


                              </div>
                          </div>

                        </div>

                      </div>
                    </div>
                    {/* segment end*/}

                  </div>

                  <div className='col-md-4'>

                    {/* segment start - veterinaryClinic contact details*/}
                    <div className='fw fl'>

                      <div className='fw fl segment_heading'>

                        <p>VETERINARY CLINIC CONTACT  </p>

                      </div>

                      <div className='fw fl b-blue rad4 p10 input_text'>

                        <div className="row">

                          <div className="col-md-6">
                            <div className="rel fw fl mb10">
                                <label>Phone</label>
                                <PhoneNumber
                                  value={dataAll.phone_1}
                                  onChange={handlePhoneChange('phone_1')}
                                  defaultCountry = 'ca' 
                                  preferredCountries={['ca', 'us']}
                                />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="rel fw fl mb10">
                                <label>Phone</label>
                                <PhoneNumber
                                  value={dataAll.phone_2}
                                  onChange={handlePhoneChange('phone_2')}
                                  defaultCountry = 'ca' 
                                  preferredCountries={['ca', 'us']}
                                />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="rel fw fl mb10">
                                <label>Phone</label>
                                <PhoneNumber
                                  value={dataAll.phone_3}
                                  onChange={handlePhoneChange('phone_3')}
                                  defaultCountry = 'ca' 
                                  preferredCountries={['ca', 'us']}
                                />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="rel fw fl mb10">
                                <label>Phone</label>
                                <PhoneNumber
                                  value={dataAll.phone_4}
                                  onChange={handlePhoneChange('phone_4')}
                                  defaultCountry = 'ca' 
                                  preferredCountries={['ca', 'us']}
                                />
                            </div>
                          </div>

                        </div>

                        <div className="row">
                          <div className="col-md-6">
                              <div className="rel fw fl mb10">
                                  <label>Fax</label>
                                 <PhoneNumber
                                  value={dataAll.fax}
                                  onChange={handlePhoneChange('fax')}
                                  defaultCountry = 'ca' 
                                  preferredCountries={['ca', 'us']}
                                />                                 
                              </div>
                          </div>
                        </div>

                        <div className="row mb5">

                                <div className="col-md-6">
                                    <div className="rel fw fl">
                                    {dataAll.whatsapp ? (
                                      <a
                                        href={`https://wa.me/${dataAll.whatsapp}`}
                                        target="_blank"
                                        className="linkSocialto"
                                        rel="noopener noreferrer"
                                      >
                                        Whatsapp
                                      </a>
                                    ) : (
                                      <label>Whatsapp</label>
                                    )}
                                          <PhoneNumber
                                            value={dataAll.whatsapp}
                                            onChange={handlePhoneChange('whatsapp')}
                                            defaultCountry = 'ca' 
                                            preferredCountries={['ca', 'us']}
                                          />    

                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="fw fl rel">
                                        <label>Email</label>
                                        <input className="" placeholder="" name="email_1" value={dataAll.email_1  || ''} type="text" onChange={handleChange}  />
                                    </div>
                                </div>
                        </div>

                        <div className="row mb5">
                                <div className="col-md-6">
                                    <div className="fw fl rel">
                                        <label>Email</label>
                                        <input className="" placeholder="" name="email_2" value={dataAll.email_2  || ''} type="text" onChange={handleChange}  />

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="fw fl rel">
                                        <label>Email</label>
                                        <input className="" placeholder="" name="email_3" value={dataAll.email_3  || ''} type="text" onChange={handleChange}  />

                                    </div>
                                </div>

                        </div>

                      </div>


                    </div>
                    {/* segment end*/}


                  </div>



                  <div className='col-md-4'>

                    {/* segment start - branches */}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>

                        <p>VETERINARY  CLINIC BRANCHES </p>

                      </div>

                      <div className='fw fl b-blue rad4 p10 input_text'>

                        <div className="row mb5">
                            <div className="col-md-12">
                                <div className="fw fl rel dentals_multi_select">
                                    <label>Branches</label>

                                    <MultiSelectAutoComplete
                                        name="branches" 
                                        model='veterinaryClinics'
                                        setValue={values => handleBranches("branches", values)}
                                        value_main={dataAll.branches  || ''}
                                    />

                                </div>
                            </div>
                        </div>



                      </div>
                    </div>
                    {/* segment end*/}

                    {/* segment start - veterinaryClinic social*/}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>

                        <p>VETERINARY  CLINIC SOCIAL MEDIA </p>

                      </div>

                      <div className='fw fl b-blue rad4 p10 input_text'>

                        <div className="row mb5">
                            <div className="col-md-12">
                                <div className="fw fl rel">
                                      { dataAll.website ?(
                                          <a href={dataAll.website} target="_blank"  className="linkSocialto" rel="noopener noreferrer">
                                            website
                                          </a>
                                          )
                                          : (
                                            <label>Website</label>
                                          )
                                        }
                                    <input  placeholder="" name="website" value={dataAll.website  || ''} type="text" onChange={handleChange}  />

                                </div>
                            </div>
                        </div>

                        <div className="row mb5">
                            <div className="col-md-12">
                                <div className="fw fl rel">
                                { dataAll.facebook ?(
                                          <a href={dataAll.facebook} target="_blank"  className="linkSocialto" rel="noopener noreferrer">
                                            Facebook
                                          </a>
                                          )
                                          : (
                                            <label>Facebook</label>
                                          )
                                        }
                                    <input  placeholder="" name="facebook" type="text"  value={dataAll.facebook  || ''} onChange={handleChange}  />

                                </div>
                            </div>
                        </div>

                        <div className="row mb5">
                                <div className="col-md-12 ">
                                    <div className="fw fl rel">
                                    { dataAll.twitter ?(
                                          <a href={dataAll.twitter} target="_blank"  className="linkSocialto" rel="noopener noreferrer">
                                            Twitter
                                          </a>
                                          )
                                          : (
                                            <label>Twitter</label>
                                          )
                                        }
                                        <input placeholder="" name="twitter" type="text" value={dataAll.twitter  || ''}   onChange={handleChange}  />

                                    </div>
                                </div>
                        </div>

                        <div className="row mb5">
                                <div className="col-md-12">
                                    <div className="fw fl rel">
                                    { dataAll.yelp ?(
                                          <a href={dataAll.yelp} target="_blank"  className="linkSocialto" rel="noopener noreferrer">
                                            Yelp
                                          </a>
                                          )
                                          : (
                                            <label>Yelp</label>
                                          )
                                        }
                                        <input placeholder="" name="yelp" type="text" value={dataAll.yelp  || ''}  onChange={handleChange}  />

                                    </div>
                                </div>
                        </div>

                        <div className="row mb5">
                                <div className="col-md-12">
                                    <div className="fw fl rel">
                                    { dataAll.linkedin ?(
                                          <a href={dataAll.linkedin} target="_blank"  className="linkSocialto" rel="noopener noreferrer">
                                            Linkedin
                                          </a>
                                          )
                                          : (
                                            <label>Linkedin</label>
                                          )
                                        }
                                        <input placeholder="" name="linkedin" type="text" value={dataAll.linkedin  || ''}  onChange={handleChange}  />

                                    </div>
                                </div>
                        </div>

                        <div className="row mb5">
                                <div className="col-md-12">
                                    <div className="fw fl rel">
                                        { dataAll.instagram ?(
                                          <a href={dataAll.instagram} target="_blank"  className="linkSocialto" rel="noopener noreferrer">
                                            Instagram
                                          </a>
                                          )
                                          : (
                                            <label>Instagram</label>
                                          )
                                        }
                                        <input placeholder="" name="instagram" type="text" value={dataAll.instagram  || ''}  onChange={handleChange}  />
                                    </div>
                                </div>
                        </div>

                        <div className="row mb5">
                          <div className="col-md-12">
                              <div className="fw fl rel ">
                                  
                                  { dataAll.youtube ?(
                                  <a href={dataAll.youtube} target="_blank"  className="linkSocialto" rel="noopener noreferrer">
                                    YouTube
                                  </a>
                                  )
                                   : (
                                    <label>Youtube</label>
                                   )
                                   }
                                  <input className="" placeholder="" name="youtube" value={dataAll.youtube || ''} type="text" onChange={handleChange}  />
                              </div>
                          </div>
                        </div>

                      </div>

                      
                    </div>
                    {/* segment end*/}

                    {/* segment start - veterinaryClinic VETERINARY  CLINIC SUPPLIERS INFORMATION*/}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>

                        <p>VETERINARY  CLINIC SUPPLIERS INFORMATION</p>

                      </div>

                      <div className='fw fl b-blue rad4 p10'>

                        <div className="row">
                          <div className="col-md-12">
                              <div className="fw fl mb10">
                                  <label>Equipment Service Provider</label>
                                      <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Select "
                                        onChange={(values) => handleChangeMultipleSelect(values, 'equipment_service_provider')}
                                        value={dataAll.equipment_service_provider  || []} 
                                      >
                                        {equipServiceProviders.map(esp => (
                                          <Option key={esp.id} value={esp.id}>
                                            {esp.name}
                                          </Option>
                                        ))}
                                      </Select>
                              </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                              <div className="fw fl mb10">
                                  <label>Handpiece Service Provider</label>
                                      <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Select "
                                        onChange={(values) => handleChangeMultipleSelect(values, 'handpiece_service_provider')}
                                        value={dataAll.handpiece_service_provider  || []} 
                                      >
                                        {handpieceServiceProviders.map(hsp => (
                                          <Option key={hsp.id} value={hsp.id}>
                                            {hsp.name}
                                          </Option>
                                        ))}
                                      </Select>
                              </div>
                          </div>
                        </div>



                        <div className="row">
                          <div className="col-md-12">
                              <div className="fw fl mb10">
                                  <label>IT Service Provider</label>
                                      <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Select "
                                        onChange={(values) => handleChangeMultipleSelect(values, 'it_service_provider')}
                                        value={dataAll.it_service_provider  || []} 
                                      >
                                        {itServiceProviders.map(hsp => (
                                          <Option key={hsp.id} value={hsp.id}>
                                            {hsp.name}
                                          </Option>
                                        ))}
                                      </Select>
                              </div>
                          </div>
                        </div>


                        <div className="row">
                          <div className="col-md-12">
                              <div className="fw fl mb10">
                                  <label>Current Supplier</label>
                                      <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Select "
                                        onChange={(values) => handleChangeMultipleSelect(values, 'current_supplier')}
                                        value={dataAll.current_supplier  || []} 
                                      >
                                        {currentSuppliers.map(hsp => (
                                          <Option key={hsp.id} value={hsp.id}>
                                            {hsp.name}
                                          </Option>
                                        ))}
                                      </Select>
                              </div>
                          </div>
                        </div>


                        <div className="row">
                          <div className="col-md-12">
                              <div className="fw fl mb10">
                                  <label>Management Software</label>
                                      <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Select "
                                        onChange={(values) => handleChangeMultipleSelect(values, 'management_software')}
                                        value={dataAll.management_software  || []} 
                                      >
                                        {managementSoftwares.map(hsp => (
                                          <Option key={hsp.id} value={hsp.id}>
                                            {hsp.name}
                                          </Option>
                                        ))}
                                      </Select>
                              </div>
                          </div>
                        </div>


                        <div className="row">
                          <div className="col-md-12">
                              <div className="fw fl mb10">
                                  <label>X-ray Software</label>
                                      <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Select "
                                        onChange={(values) => handleChangeMultipleSelect(values, 'xray_software')}
                                        value={dataAll.xray_software  || []} 
                                      >
                                        {xraySoftwares.map(hsp => (
                                          <Option key={hsp.id} value={hsp.id}>
                                            {hsp.name}
                                          </Option>
                                        ))}
                                      </Select>
                              </div>
                          </div>
                        </div>


                        <div className="row">
                          <div className="col-md-12">
                              <div className="fw fl mb10">
                                  <label>Sales Person</label>
                                      <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Select "
                                        onChange={(values) => handleChangeMultipleSelect(values, 'sales_persons')}
                                        value={dataAll.sales_persons  || []} 
                                      >
                                        {salesPersons.map(hsp => (
                                          <Option key={hsp.id} value={hsp.id}>
                                            {hsp.full_name}
                                          </Option>
                                        ))}
                                      </Select>
                              </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                              <div className="fw fl mb10">
                                  <label>Equipment Service Technician</label>
                                      <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Select "
                                        onChange={(values) => handleChangeMultipleSelect(values, 'equipment_service_technician')}
                                        value={dataAll.equipment_service_technician  || []} 
                                      >
                                        {equipmentServiceTechnicians.map(hsp => (
                                          <Option key={hsp.id} value={hsp.id}>
                                            {hsp.full_name}
                                          </Option>
                                        ))}
                                      </Select>
                              </div>
                          </div>
                        </div>


                        <div className="row">
                          <div className="col-md-12">
                              <div className="fw fl mb10">
                                  <label>IT Service Technician</label>
                                      <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Select "
                                        onChange={(values) => handleChangeMultipleSelect(values, 'it_service_technician')}
                                        value={dataAll.it_service_technician  || []} 
                                      >
                                        {itServiceTechnicians.map(hsp => (
                                          <Option key={hsp.id} value={hsp.id}>
                                            {hsp.full_name}
                                          </Option>
                                        ))}
                                      </Select>
                              </div>
                          </div>
                        </div>




                      </div>
                    </div>
                    {/* segment end*/}   
                 
                    {/* segment start - veterinaryClinic description*/}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>

                        <p>ABOUT VETERINARY  CLINIC</p>

                      </div>

                      <div className='fw fl b-blue rad4 p20'>
                        <textarea className="fs12 p15 fw fl b-grey " name="description" value={dataAll.description  || ''}  onChange={handleChange} rows="10" cols="30" ></textarea>
                      </div>
                    </div>
                    {/* segment end*/} 

                    {/* segment start service*/}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>

                        <p>VETERINARY  CLINIC SERVICES</p>

                      </div>

                      <div className='fw fl b-blue rad4 p20'>

                        <Checkbox.Group
                            className='checkoboxMultiple fw fl'

                            options={clinicServices.slice()
                              .sort((a, b) => a.name.localeCompare(b.name)) 
                              .map(cs => ({ label: cs.name, value: cs.id }))
                            }
                            onChange={handleCheckboxChange('veterinary_clinic_service')}
                            value={dataAll.veterinary_clinic_service  || ''} 
                        />

                      </div>

                    </div>
                    {/* segment end*/} 

                    {/* segment start - division access */}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>
                        <p>DIVISION ACCESS</p>
                      </div>

                      <div className='fw fl b-blue rad4 p20'>

                        <Checkbox.Group
                            className='checkoboxMultiple fw fl'
                            options={divisionAccess.slice()
                              .sort((a, b) => a.name.localeCompare(b.name)) 
                              .map(da => ({ label: da.name, value: da.id }))
                            }
                            onChange={handleCheckboxChange('division_access')}
                            value={dataAll.division_access  || ''} 
                        />

                      </div>

                    </div>
                    {/* segment end*/} 

                    {/* segment start - profile pic */}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>
                        <p>VETERINARY CLINIC IMAGE</p>
                      </div>

                      <div className='fw fl b-blue rad4 p20 rel image_upload'>

                        <Upload
                          listType="picture-circle"
                          fileList={fileList}
                          onChange={handleUploadChange}
                          accept="image/*" 
                          maxCount={1}
                          customRequest={({ file, onSuccess }) => {
                            onSuccess(); 
                          }}
                        >
                          <Button className='abs right20' icon={<UploadOutlined />}>Browse Image</Button>
                        </Upload>

                      </div>

                    </div>
                    {/* segment end*/} 

                    {loading && 
                      <>
                        <div className="fw fl">
                          <LoadingOutlined spin className="fs40 cs_b" />
                        </div>
                      </>
                    }

                    <div className="fw fl  mt50 ">                            
                      {/* new design end */}
                      {success && 
                      <div className="fw fl success_status">
                          <p>{successMessage}</p>
                      </div>
                      }      

                      {!loading && errorStatus && 
                      <div className="fw fl error_status">
                          <HtmlRender message={error} />
                      </div>
                      }

                      <div className="fw fl ">
                        {errors.length > 0 && (
                          <div className="error_messages">
                              {errors.map((error, index) => (
                                  <p key={index} >{error}</p>
                              ))}
                          </div>
                        )}
                      </div>

                      <div className="fw fl mb20">
                        <button type="submit" className="btn_submit" >Submit</button>
                      </div>
                    </div>

                  </div>

                </div>
              </div>

            </div>

          </form>

        </div>

      </div>





      <Footer />
    </>
  );
};

export default VeterinaryClinicEdit;



