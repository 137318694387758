

export function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
}



export function validateWebsite(website) {
    const re = /^(https?:\/\/)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(?:\/[^\s]*)?(\/)?$/;
    const trimmedWebsite = String(website).trim().toLowerCase();
    return re.test(trimmedWebsite);
}



export function validateFormOne(moduleName) {
    const errors = [];

    if (!moduleName.first_name) {
        errors.push('First Name is required.');
    }
    if (!moduleName.phone_mobile) {
        errors.push('Phone (mobile) is required.');
    }

    if (!moduleName.email) {
        errors.push('Email_1 is required.');
    } else if (!validateEmail(moduleName.email)) {
        errors.push('Email_1 is not valid.');
    }

    if (moduleName.email_2) {
        if (!validateEmail(moduleName.email_2)) {
            errors.push('Email_2 is not valid.');
        }
    }

    if (!moduleName.website) {
        errors.push('Website is required.');
    } else if (!validateWebsite(moduleName.website)) {
        errors.push('Website is not valid.');
    }

    if (!moduleName.unit_suite) {
        errors.push('Unit/Suite is required.');
    }
    if (!moduleName.street_address) {
        errors.push('Street Address is required.');
    }
    if (!moduleName.country) {
        errors.push('Country is required.');
    }

    if (!moduleName.province_state) {
        errors.push('Province/State is required.');
    }

    if (!moduleName.city) {
        errors.push('City is required.');
    }

    if (!moduleName.postal_code) {
        errors.push('Postal code is required.');
    }

    return errors;
}

export function validateFormTwo(moduleName) {
    const errors = [];
    if (!moduleName.name) {
        errors.push('Name is required.');
    }
    if (!moduleName.phone_1) {
        errors.push('Phone_1 is required.');
    }    
    if (!moduleName.email_1) {
        errors.push('Email_1 is required.');
    } else if (!validateEmail(moduleName.email_1)) {
        errors.push('Email_1 is not valid.');
    }    

    if (moduleName.email_2) {
        if (!validateEmail(moduleName.email_2)) {
            errors.push('Email_2 is not valid.');
        }
    }

    if (moduleName.email_3) {
        if (!validateEmail(moduleName.email_3)) {
            errors.push('Email_3 is not valid.');
        }
    }


    if (!moduleName.website) {
        errors.push('Website is required.');
    } else if (!validateWebsite(moduleName.website)) {
        errors.push('Website is not valid.');
    }    
    
    
    if (!moduleName.unit_suite) {
        errors.push('Unit/Suite is required.');
    }    
    if (!moduleName.street_address) {
        errors.push('Street Address is required.');
    }    

    if (!moduleName.country) {
        errors.push('Country is required.');
    }

    if (!moduleName.province_state) {
        errors.push('Province/State is required.');
    }

    if (!moduleName.city) {
        errors.push('City is required.');
    }

    if (!moduleName.postal_code) {
        errors.push('Postal code is required.');
    }

    return errors;
}




