import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../../global/components/common/NavBar";
import Footer from "../../../global/components/common/Footer";
import { useNavigate, Link } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate()
  const baseURL = process.env.REACT_APP_API_URL
  const token = localStorage.getItem("token")
  const [data, setData] = useState({});


  useEffect(() => {
    document.title = "Dashboard";

    if (!token){
      navigate(`/login`);
    }


    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token"); 
        const response = await axios.get(`${baseURL}/dashboard/`, {
          headers: {
            Authorization: `token ${token}`,
          },
        });
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [baseURL]);

  return (
    <>
  
      <Navbar />

        <div className="w98p ml1p fl mt20 h75vh_m">
          <div className="dashboard_container">
            {Object.keys(data).map((key) => (
              <Link
                key={key}
                to={`/${key}`} 
                className="dashboard_item"
              >
                <h2 className="dashboard_heading">
                  {key.replace("_", " ")}
                </h2>
                <p className="dashboard_text">
                  <span>Total Records:</span> {data[key].total_records}
                </p>
                <p className="dashboard_text">
                  <span>Records in Canada:</span>{" "}
                  {data[key].records_in_canada}
                </p>
                <p className="dashboard_text">
                  <span>Records in USA:</span>{" "}
                  {data[key].records_in_usa_or_america}
                </p>

                <p className="dashboard_text">
                  <span>Total Active:</span>{" "}
                  {data[key].is_active}
                </p>

                <p className="dashboard_text">
                  <span>Total Verified:</span>{" "}
                  {data[key].is_verified}
                </p>

                <p className="dashboard_text">
                  <span>Total Phone Number:</span>{" "}
                  {data[key].total_phone}
                </p>            
                <p className="dashboard_text">
                  <span>Total Email Address:</span>{" "}
                  {data[key].total_email}
                </p>


              </Link>
            ))}
          </div>
        </div>

      <Footer />
    </>
  );
};

export default Dashboard;


