import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate} from 'react-router-dom'
import Navbar from "../../../global/components/common/NavBar"
import Footer from "../../../global/components/common/Footer"
import { Table, Menu } from 'antd'
import TableTop from '../../../global/utils/TableTop'
import TableColumnOne from '../../../global/utils/TableColumnsOne'
import PaginationDropdown from '../../../global/utils/PaginationDropdown'
import { pageSizeOptions } from '../../../global/utils/PageConfig'
 
const VetClinicAll = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30); 
  const [searchQuery, setSearchQuery] = useState('');
  const [sortedData, setSortedData] = useState([]);

  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");

  const columns = TableColumnOne()

  const fetchData = async (page, pageSize, searchQuery) => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}/veterinaryClinics/api/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          page,
          page_size: pageSize,
          search: searchQuery, 
        },
      });
      const data = response.data.data.results;
      const count = response.data.data.count;

      setData(data);
      setTotal(count);
      setSortedData(data);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Dentals | Veterinary Clinic";
    if (!token){
      navigate(`/login`)
    }


    fetchData(page, pageSize, searchQuery);
  }, [page, pageSize, searchQuery, token]);

  const handleTableChange = (pagination, filters, sorter) => {
    let sortedData = [...data];

    if (sorter.order) {
      sortedData = sortedData.sort((a, b) => {
        const key = sorter.columnKey;
        const aValue = a[key];
        const bValue = b[key];

        if (sorter.order === 'ascend') {
          return compareValues(aValue, bValue, key);
        } else {
          return compareValues(bValue, aValue, key);
        }
      });
    }

    setSortedData(sortedData);
  };

  const compareValues = (a, b, key) => {
    if (typeof a === 'string' && typeof b === 'string') {
      return a.localeCompare(b);
    }
    if (typeof a === 'number' && typeof b === 'number') {
      return a - b;
    }
    if (typeof a === 'boolean' && typeof b === 'boolean') {
      return (a === b) ? 0 : (a ? 1 : -1);
    }
    return 0
  };

  const handleRowClick = (record) => {
    navigate(`/veterinaryclinic/${record.id}`);
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
    setPage(1)
  }

  const handleMenuClick = (e) => {
    setPageSize(parseInt(e.key, 10));
  }

  

  const menu = (
    <Menu
      items={pageSizeOptions.map(size => ({
        key: size,
        label: `${size}`,
      }))}
      onClick={(e) => {
        const selectedSize = parseInt(e.key, 10);
        setPageSize(selectedSize)
        handleMenuClick(e)
      }}
    />
  )

  return (
    <>
      <Navbar />
      <div className="w98p ml1p fl mt20 h75vh_m">
        <div className='fw fl box-shadow-2 mb5 bg-w p10'>

          <TableTop  title="Veterinary Clinics"  link="/createVeterinaryClinic"  handleSearch={handleSearch}  />

          <div className="fw fl">

            <div className="table_container">
              <Table
                columns={columns}
                dataSource={sortedData}
                pagination={false}
                loading={loading}
                onChange={handleTableChange}
                rowKey="id"
                onRow={(record) => ({
                  onClick: () => handleRowClick(record),
                })}
                rowClassName="cp"
                className='responsive_table'
              />
            </div>  

            <PaginationDropdown
              page={page}
              pageSize={pageSize}
              total={total}
              setPage={setPage}
              setPageSize={setPageSize}
              menu={menu}
            />

          </div>

        </div>
      </div>
      <Footer />
    </>
  );
};

export default VetClinicAll;
