import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate} from 'react-router-dom';
import Navbar from "../../../global/components/common/NavBar";
import Footer from "../../../global/components/common/Footer";
import {  Upload, Button  } from 'antd'
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'


const Settings = () => {

    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token")
    const is_superuser = localStorage.getItem("is_superuser")

    const initial = {
        clinicFile: null,    
    }
    
    const [initialState, setInitialState] = useState(initial);

    const [loading, setLoading] = useState(false);
    const [loadingC, setLoadingC] = useState(false);
    const [clinicFile, setClinicFile] = useState(null);
    const [clinicFIleList, setClinicFIleList] = useState([]);
    const [downloadUrl, setDownloadUrl] = useState('');

    const handleUploadResumeChange = ({ fileList: newFileList }) => {
        const updatedFileList = newFileList.slice(-1);
      
        setInitialState((prevState) => ({
          ...prevState,
          clinicFile: updatedFileList[0]?.originFileObj || null,
        }));

        setClinicFile(updatedFileList[0]?.originFileObj || null);
        setClinicFIleList(updatedFileList);
      }

    useEffect(() => {

        document.title = "Dentals | Settings"

        if (!token){
            navigate(`/login`)
        }
        if (!is_superuser){
            navigate(`/`)
        }

        // console.log(is_superuser)

    }, []);

    const handleSubmit = async (e, addMore = false) => {
        e.preventDefault()

        setLoading(true)

        try {
          let response;
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${token}`,
            },
          };
    
          response = await axios.post(
            `${baseURL}/clinics/api/bulk_upload/`,
            initialState,
            config
          );
    
          var status = response.data.status
          var message = response.data.message

          if (status === 1){
            toast.success(message)
          }else{
            toast.error(message)
          }

        } catch (error) {
          console.error("Error submitting dentalTechnician:", error);
        } finally {
          setLoading(false)
        }

      }


    const handleSubmitClinics = async (e, addMore = false) => {
        e.preventDefault()
        setLoadingC(true)
        try {
          let response;
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${token}`,
            },
          };
    
          response = await axios.post(
            `${baseURL}/clinics/api/download_clinics/`,
            initialState,
            config
          );
    
          var status = response.data.status
          var message = response.data.message
          var file_url = response.data.file_url

          console.log(file_url)
            
          if (status === 1){
            toast.success(message)
            setDownloadUrl(file_url)
          }else{
            toast.error(message)
          }

        } catch (error) {
          console.error("Error submitting dentalTechnician:", error);
        } finally {
          setLoadingC(false)
        }

      }


  return (
    <>
      <Navbar />
        <div className="w98p ml1p fl mt20 h75vh_m">

            <div className="fw fl">

              <div className="w45p fl">

                  <form onSubmit={handleSubmit} className="">
                      {/* segment start - file */}
                      <div className='fw fl mb20'>

                        <div className='fw fl segment_heading'>
                          <p>Upload Clinics</p>
                        </div>

                        <div className='fw fl b-blue rad4 p20 rel image_upload'>

                          <Upload
                            accept=".csv, .doc, .docx"
                            fileList={clinicFIleList}
                            onChange={handleUploadResumeChange}
                            maxCount={1}
                            customRequest={({ file, onSuccess }) => {
                              setClinicFile(file.originFileObj);
                              onSuccess();
                            }}
                          >
                            <Button className='abs right20 top15' icon={<UploadOutlined />}>Upload file</Button>
                          </Upload>

                        </div>

                      </div>
                      {/* segment end*/} 


                      {loading ? (
                        <>
                          <div className="fw fl">
                            <LoadingOutlined spin className="fs40 cs_b" />
                          </div>
                        </>
                      ) : (
                        <div className="fw fl mb20">
                          <button type="submit" className="btn_submit mr20" >Submit</button>
                        </div>
                      )
                      }

                  </form>
              </div>

              <div className="w45p fr tr">
                <form onSubmit={handleSubmitClinics} className="">

                  <div className='fw fl mb20'>
                    <div className='fw fl segment_heading'>
                      <p>Download Clinics</p>
                    </div>

                    <div className='fw fl b-blue rad4 p20 rel image_upload'>

                    {loadingC ? (
                          <>
                          <div className="fw fl">
                              <LoadingOutlined spin className="fs40 cs_b" />
                          </div>
                          </>
                        ) : (
                          <div className="fw fl mb20">
                            <button type="submit" className="btn_submit" >Download Clinics</button>
                          </div>
                        )
                      }




                      <div className="fw fl">
                        {downloadUrl && (
                          <a href={downloadUrl} className="btn_submit" download>
                            Download CSV
                          </a>
                        )}
                      </div>

                    </div>
                  </div>

                </form>                
              </div>       

            </div>

        </div>
      <Footer />
    </>
  );
};

export default Settings
