import React, { useState, useEffect } from "react"
import axios from "axios"
import { useNavigate} from 'react-router-dom'
import Navbar from "../../../global/components/common/NavBar"
import Footer from "../../../global/components/common/Footer"
import HtmlRender from '../../../global/utils/HtmlRender'
import RadioButtonGroup from "../../../global/utils/RadioButtonGroup"
import { DatePicker, Checkbox, Upload, Button  } from 'antd'
import dayjs from 'dayjs'
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import { validateFormOne } from '../../../global/utils/Validators'
import { generateYearOptions } from "../../../global/utils/utils"

import PhoneNumber from '../../../global/utils/PhoneNumber'
import { PhoneNumberValidateTwo } from '../../../global/utils/PhoneNumberValidate'

import AddressInput from "../../../global/utils/AddressInput"
import { disableDate } from '../../../global/utils/AtLeast18'

import { initialStateHygienist } from '../../../global/utils/PageConfig'
const initialState = initialStateHygienist

const HygienistForm = () => {
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const gmap_api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const yearOptions = generateYearOptions()

  const [dataAll, setDataAll] = useState(initialState);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorStatus, setErrorStatus] = useState(false);
  const [error, setError] = useState('');
  const [errors, setErrors] = useState([]);

  const [genders, setGenders] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [divisionAccess, setDivisionAccess] = useState([]);
  const [loading, setLoading] = useState(false);
  const [speciality, setSpeciality] = useState([]);
  const [typeofwork, setTypeofwork] = useState([]);
  const [howhearus, setHowhearus] = useState([]);

  const [profileImageList, setProfileImageList] = useState([]);
  const [resumeList, setResumeList] = useState([]);



  useEffect(() => {

    document.title = "Create Hygienist";
    if (!token){
      navigate(`/login`)
    }

    
    const apiSpecialityUrl = `${baseURL}/hygienists/api/createdata/`

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
    }
    axios({
        'method':'GET',
        'url':apiSpecialityUrl,
        'headers': headers,
    })
    .then((response) => {
      setGenders(response.data.gender)
      setLanguages(response.data.language)
      setDivisionAccess(response.data.division_access)

      setSpeciality(response.data.speciality)
      setTypeofwork(response.data.type_of_work)
      setHowhearus(response.data.how_hear_us)
    })
    .catch((error) => {
        console.log(error)
    });
  }, [baseURL, navigate, token]);

  // handle radio change 
  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setDataAll((prevState) => ({
      ...prevState,
      [name]: value === 'true' 
    }));
  }
  // handle radio change end

  // Handle date changes
  const handleChangeDate = (date, dateString, name) => {

    const dayjsDate = dayjs(dateString, 'MM/DD/YYYY');
    const formattedDate = dayjsDate.isValid() ? dayjsDate.format('YYYY-MM-DD') : null;

    setDataAll((prevState) => ({
      ...prevState,
      [name]: formattedDate,
    }));
  };
  // Handle date changes  end


  const handleUploadProfileImageChange = ({ fileList: newFileList }) => {
    const updatedFileList = newFileList.slice(-1);
  
    setDataAll((prevState) => ({
      ...prevState,
      profile_image: updatedFileList[0]?.originFileObj || null,
    }));
    
    setProfileImageList(updatedFileList);
  }

  const handleUploadResumeChange = ({ fileList: newFileList }) => {
    const updatedFileList = newFileList.slice(-1);
  
    setDataAll((prevState) => ({
      ...prevState,
      resume: updatedFileList[0]?.originFileObj || null,
    }));
  
    setResumeList(updatedFileList);
  }

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    setDataAll((prevState) => ({
      ...prevState,
      [name]: type === "file" ? files[0] : type === "checkbox" ? checked : value,
    }));
  };
 
  const handleCheckboxChange = (name) => (checkedValues) => {

    setDataAll((prevState) => ({
      ...prevState,
      [name]: checkedValues,
    }))
  }
  
//phone handle 
const handlePhoneChange = (field) => (value) => {
  setDataAll((prevState) => ({
    ...prevState,
    [field]: value,
  }));
};
//phone handle 


  const handleSubmit = async (e, addMore = false) => {
    e.preventDefault();

    const validationErrors = validateFormOne(dataAll)
    if (validationErrors.length > 0) {
      setError('')
      setErrors(validationErrors)
      return
    }

    const validationPhoneErrors = PhoneNumberValidateTwo(dataAll)
    if (validationPhoneErrors.length > 0) {
        setError('')
        setErrors(validationPhoneErrors)
        return
    }    


    setErrors([]);
    setLoading(true)
    try {
      let response;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      };

      response = await axios.post(
        `${baseURL}/hygienists/api/create/`,
        dataAll,
        config
      );

      var status = response.data.status
      var message = response.data.message
        
      if (status === 1){
        setSuccess(true)
        setSuccessMessage(message)
        setErrorStatus(false)
        setDataAll(initialState)
        toast.success(message)

        if (!addMore) {
          setTimeout(() => {
            navigate('/hygienists')
          }, 2000)
        }else{
          setTimeout(() => {
            setSuccess(false)
            setSuccessMessage("")
          }, 2000)          
        }


      }else{
        setSuccess(false)
        setErrorStatus(true)
        setError(message)
        setErrors([])
      }


    } catch (error) {
      console.error("Error submitting hygienist:", error);
    } finally {
      setLoading(false)
    }    
  
  }

  return (
    <>
      <Navbar />
      <div className="w98p ml1p fl mt20 h75vh_m">

        <div className='fw fl box-shadow-2 mb5 bg-w p10'>

        {/* <h2 className="fw fl tc mb20">Create Hygienist</h2> */}

        <form onSubmit={handleSubmit} className="">

          <div className='fw fl'>

            {/* new design start */}

            <div className="container-fluid">
              <div className="row">

                {/* left section start */}
                <div className="col-md-4">

                    {/* segment start Hygienist information*/}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>
                        <p>HYGIENIST INFORMATION </p>
                      </div>

                      <div className='fw fl b-blue rad4 p10 input_text'>

                        <div className="row">
                          <div className="col-md-6">
                              <div className="fw fl rel ">
                                  <label>First Name</label>
                                  <input className="required" name="first_name" type="text" onChange={handleChange} />
                              </div>
                          </div>                          
                          
                          <div className="col-md-6">
                              <div className="fw fl rel ">
                                  <label>Last Name</label>
                                  <input className="required" name="last_name" type="text" onChange={handleChange} />
                              </div>
                          </div>
                        </div>

                      
                        <div className="row">

                            <div className="col-md-12">
                                <div className="fw fl rel ">
                                    <label>Street Address</label>
                                    <AddressInput
                                      gmapApiKey={gmap_api_key}
                                      dataAll={dataAll}
                                      setDataAll={setDataAll}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="fw fl rel ">
                                    <label>Unit/Suite</label>
                                    <input className=""  name="unit_suite" value={dataAll.unit_suite || ''}  type="text" onChange={handleChange} />
                                </div>
                            </div>


                            <div className="col-md-6">
                                <div className="fw fl rel">
                                    <label>City</label>
                                    <input className=""  name="city" value={dataAll.city || ''}   type="text" onChange={handleChange} />
                                </div>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-md-4">
                                <div className="fw fl rel">
                                    <label>Province/State</label>
                                    <input className=""  name="province_state" value={dataAll.province_state || ''}   type="text" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="fw fl rel">
                                    <label>Country</label>
                                    <input className=""  name="country" type="text" value={dataAll.country || ''}   onChange={handleChange} />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="fw fl rel">
                                    <label>Postal/Zip Code</label>
                                    <input className=""  name="postal_code" value={dataAll.postal_code || ''}   type="text" onChange={handleChange} />
                                </div>
                            </div>
                        </div>                        
                        
                        <div className="row">
                            <div className="col-md-6">
                              <span className="fl mt8">Date of Birth</span>
                                <DatePicker 
                                  onChange={(date, dateString) => handleChangeDate(date, dateString, 'date_of_birth')} 
                                  format="MM/DD/YYYY" 
                                  disabledDate={disableDate}
                                />
                            </div>

                            <div className="col-md-6">
                              <span className="fl mt8">Gender</span>
                              <select className='fw fl custom_select' name="gender"  onChange={handleChange} >
                                    <option value="">Select a Gender</option>
                                    {genders.map(g => (
                                        <option key={g.id} value={g.id}>
                                            {g.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>          
              




                      </div>
                    </div>
                    {/* segment end*/} 


                    {/* segment start - clinic status*/}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>
                        <p>HYGIENIST STATUS </p>
                      </div>

                      <div className='fw fl b-blue rad4 p10'>

                          <div className="fw fl ">
                            <span className="fw fl">
                              <RadioButtonGroup
                                label="Is Active"
                                name="is_active"
                                selectedValue={dataAll.is_active}
                                onChange={handleRadioChange}
                                leftw="w50p"
                                rightw="w50p"
                              />
                            </span>
                          </div>

                        <p className='fw fl fs12 lh15 mt15'>If status is Inactive then the hygienist is no longer to get login access into the divisions</p>

                      </div>

                    </div>
                    {/* segment end*/}


                    {/* segment start - clinic contact details*/}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>
                        <p>HYGIENIST CONTACT INFORMATION </p>
                      </div>

                      <div className='fw fl b-blue rad4 p10 input_text'>

                      <div className="row">
                          <div className="col-md-6">
                            <div className="rel fw fl mb10">
                                <label>Phone(mobile)</label>
                                <PhoneNumber
                                  value={dataAll.phone_mobile}
                                  onChange={handlePhoneChange('phone_mobile')}
                                  defaultCountry = 'ca' 
                                  preferredCountries={['ca', 'us']}
                                />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="rel fw fl mb10">
                                <label>Phone(other)</label>
                                <PhoneNumber
                                  value={dataAll.phone_other}
                                  onChange={handlePhoneChange('phone_other')}
                                  defaultCountry = 'ca' 
                                  preferredCountries={['ca', 'us']}
                                />
                            </div>
                          </div>
                        </div>
                      
                        <div className="row">

                          <div className="col-md-6">
                              <div className="fw fl rel mb10">
                                  <label>Whatsapp</label>

                                  <PhoneNumber
                                    value={dataAll.whatsapp}
                                    onChange={handlePhoneChange('whatsapp')}
                                    defaultCountry = 'ca' 
                                    preferredCountries={['ca', 'us']}
                                  />

                              </div>
                          </div>

                          <div className="col-md-6">
                              <div className="fw fl rel mb10">
                                  <label>Fax</label>
                                  <PhoneNumber
                                    value={dataAll.fax}
                                    onChange={handlePhoneChange('fax')}
                                    defaultCountry = 'ca' 
                                    preferredCountries={['ca', 'us']}
                                  />
                              </div>
                          </div>

                        </div>

                        <div className="row mb5">
                          <div className="col-md-6">
                              <div className="fw fl rel ">
                                  <label>Email</label>
                                  <input className="" placeholder="" name="email" type="text" onChange={handleChange}  />

                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="fw fl rel ">
                                  <label>Email</label>
                                  <input className="" placeholder="" name="email_2" type="text" onChange={handleChange}  />

                              </div>
                          </div>
                        </div>

                        <div className="row mb5">
                            <div className="col-md-12">
                                <div className="fw fl rel ">
                                    <label>Website</label>
                                    <input className="" placeholder="" name="website" type="text" onChange={handleChange}  />

                                </div>
                            </div>
                        </div>

                        <div className="row mb5">
                          <div className="col-md-12">
                              <div className="fw fl rel ">
                                  <label>Facebook</label>
                                  <input className="" placeholder="" name="facebook" type="text" onChange={handleChange}  />

                              </div>
                          </div>
                        </div>

                        <div className="row mb5">
                              <div className="col-md-12 ">
                                  <div className="fw fl rel ">
                                      <label>Twitter</label>
                                      <input className="" placeholder="" name="twitter" type="text"  onChange={handleChange}  />

                                  </div>
                              </div>
                        </div>

                        <div className="row mb5">
                              <div className="col-md-12">
                                  <div className="fw fl rel ">
                                      <label>Linkedin</label>
                                      <input className="" placeholder="" name="linkedin" type="text" onChange={handleChange}  />

                                  </div>
                              </div>
                        </div>

                        <div className="row mb5">
                              <div className="col-md-12">
                                  <div className="fw fl rel ">
                                      <label>Instagram</label>
                                      <input className="" placeholder="" name="instagram" type="text" onChange={handleChange}  />
                                  </div>
                              </div>
                        </div>

                        <div className="row mb5">
                          <div className="col-md-12">
                              <div className="fw fl rel ">
                                  <label>Youtube</label>
                                  <input className="" placeholder="" name="youtube" type="text" onChange={handleChange}  />
                              </div>
                          </div>
                        </div>


                      </div>

                    </div>
                    {/* segment end*/}


                    {/* segment start - languages */}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>
                        <p>LANGUAGE SPEAK</p>
                      </div>

                      <div className='fw fl b-blue rad4 p20'>

                        <Checkbox.Group
                            className='checkoboxMultiple fw fl'
                            options={languages.map(ls => ({ label: ls.name, value: ls.id }))}
                            onChange={handleCheckboxChange('language')}
                        />

                      </div>

                    </div>
                    {/* segment end*/} 



                </div>
                {/* left section end */}

                {/* middle section start */}
                <div className="col-md-4">
                
                    {/* segment start Hygienist information*/}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>
                        <p>PROFESSIONAL INFORMATION </p>
                      </div>


                      <div className='fw fl b-blue rad4 p10 input_text'>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="fw fl">
                                    <label>Uniersity/College</label>
                                    <input className="required input_number_full" name="university_college" type="text" onChange={handleChange} />
                            </div>                          
                          </div>                          
                        </div>

                        <div className="row">
                          <div className="col-md-12">

                            <div className="fw fl mb10">
                              <label>Year Graduated</label>
                              <select className='fw fl custom_select' name="year_graduated"   onChange={handleChange} >
                                  <option value="">Select </option>
                                  {yearOptions.map(year => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                  ))}
                              </select>                                      
                            </div>  
                        
                          </div>                          
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="fw fl">
                                <div className="Uniersity/College">
                                    <label>Year of Experience</label>
                                    <input className="required input_number_full" name="year_of_experience" type="number" min="0" onChange={handleChange} />
                                </div>
                            </div>                          
                          </div>                          
                        </div>


                        <div className="row">
                          <div className="col-md-12">
                              <div className="fw fl mb10">
                                  <label>Type of work</label>
                                  <select className='fw fl custom_select' name="type_of_work" onChange={handleChange} >
                                      <option value="">Select </option>
                                      {typeofwork.map(t => (
                                          <option key={t.id} value={t.id}>
                                              {t.name}
                                          </option>
                                      ))}
                                  </select>  
                              </div>
                          </div>                          
                        </div>


                        <div className="row">
                          <div className="col-md-12">
                              <div className="fw fl mb10">
                                  <label>Speciality</label>

                                  <select className='fw fl custom_select' name="speciality" onChange={handleChange} >
                                      <option value="">Select </option>
                                      {speciality.map(s => (
                                          <option key={s.id} value={s.id}>
                                              {s.name}
                                          </option>
                                      ))}
                                  </select>                               
                              
                              </div>
                          </div>                          
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                              <div className="fw fl mb10">
                                  <label>How do you hear about us?</label>
                                  <select className='fw fl custom_select' name="how_hear_us" onChange={handleChange} >
                                      <option value="">Select</option>
                                      {howhearus.map(h => (
                                          <option key={h.id} value={h.id}>
                                              {h.name}
                                          </option>
                                      ))}
                                  </select>                                 
                              </div>
                          </div>                          
                        </div>




                      </div>
                    </div>
                    {/* segment end*/}                 
                
                
                </div>
                {/* middle section end */}

                {/* right section start  */}
                <div className="col-md-4">

                    {/* segment start - clinic description*/}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>
                        <p>ABOUT HYGIENIST</p>
                      </div>

                      <div className='fw fl b-blue rad4 p20'>
                        <textarea className="fs12 p15 fw fl b-grey " name="description" onChange={handleChange} rows="10" cols="30" ></textarea>
                      </div>
                    </div>
                    {/* segment end*/} 

                    {/* segment start - division access */}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>
                        <p>DIVISION ACCESS</p>
                      </div>

                      <div className='fw fl b-blue rad4 p20'>

                        <Checkbox.Group
                            className='checkoboxMultiple fw fl'
                            options={divisionAccess.slice()
                              .sort((a, b) => a.name.localeCompare(b.name)) 
                              .map(da => ({ label: da.name, value: da.id }))
                            }
                            onChange={handleCheckboxChange('division_access')}
                        />

                      </div>

                    </div>
                    {/* segment end*/} 



                    {/* segment start - resume */}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>
                        <p>HYGIENIST RESUME</p>
                      </div>

                      <div className='fw fl b-blue rad4 p20 rel image_upload'>

                        <Upload
                          accept=".pdf, .doc, .docx"
                          fileList={resumeList}
                          onChange={handleUploadResumeChange}
                          maxCount={1}
                          customRequest={({ file, onSuccess }) => {
                            onSuccess();
                          }}
                        >
                          <Button className='abs right20 top15' icon={<UploadOutlined />}>Resume</Button>
                        </Upload>

                      </div>

                    </div>
                    {/* segment end*/} 

                    {/* segment start - profile pic */}
                    <div className='fw fl mb20'>

                      <div className='fw fl segment_heading'>
                        <p>HYGIENIST IMAGE</p>
                      </div>

                      <div className='fw fl b-blue rad4 p20 rel image_upload'>

                        <Upload
                          listType="picture-circle"
                          fileList={profileImageList}
                          onChange={handleUploadProfileImageChange}
                          accept="image/*"
                          maxCount={1}
                          customRequest={({ file, onSuccess }) => {
                            onSuccess();
                          }}
                        >
                          <Button className='abs right20' icon={<UploadOutlined />}>Browse Image</Button>
                        </Upload>

                      </div>

                    </div>
                    {/* segment end*/} 

                    {loading && 
                      <>
                        <div className="fw fl">
                          <LoadingOutlined spin className="fs40 cs_b" />
                        </div>
                      </>
                    }

                    <div className="fw fl  mt50 ">                            
                      {success && 
                      <div className="fw fl success_status">
                          <p>{successMessage}</p>
                      </div>
                      }      

                      {!loading && errorStatus && 
                      <div className="fw fl error_status">
                          <HtmlRender message={error} />
                      </div>
                      }

                      <div className="fw fl ">
                        {errors.length > 0 && (
                          <div className="error_messages">
                              {errors.map((error, index) => (
                                  <p key={index} >{error}</p>
                              ))}
                          </div>
                        )}
                      </div>

                        <div className="fw fl mb20">
                          <button type="submit" className="btn_submit mr20" >Submit</button>
                          <span className="btn_submit cp" onClick={(e) => handleSubmit(e, true)}>Save and Add More</span>
                        </div>

                    </div>





                </div>
                {/* right section end  */}

              </div>
            </div>





            
            {/* new design end */}

          </div>

        </form>

        </div>

      </div>


      <Footer />
    </>
  );
};

export default HygienistForm;
