import React, { useEffect } from "react";
import Footer from "../../../global/components/common/Footer";

const Logout = ({ authenticateUser }) => {

  useEffect(() => {
    document.title = "Logout | 1db"
    localStorage.removeItem('token')
    localStorage.removeItem('is_superuser')
    window.location.href = '/login'
  },[])


  return (

    <>
    <div className="fw fl h85vh_m">
        <div className="fw fl mt100 tc">
            <p className="fs20 lh20">Logout Successfully!</p>          
        </div>
    </div>

    <Footer/>

    </>


  );
};

export default Logout;
